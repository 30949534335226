@import "variables";

h1 {
  font-size: 70px;
  font-family: $corp-font-titles;
  white-space: pre-line;
}

.subtitleText {
  font-size: 21px;
  color: #ff3a2e;
  margin: 30px 0px 30px 0px;
  font-family: $subtitle-font;
}

.textComponentP {
  font-size: 25px;
  margin: 20px 0px 30px 0px;
}

@include mobile {
  h1 {
    font-size: 40px;
  }

  .textComponentP {
    font-size: 20px;
    margin-bottom: 50px;
  }

  .subtitleText {
    font-size: 19px;
  }
}
