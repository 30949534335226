@import "variables";

.formContainer {
  padding: 50px;
  text-align: center;
  display: flex;
  justify-content: center;
}

.mailchimp-input {
  background-color: white;
  padding: 15px;
  border-radius: 8px 0px 0px 8px;
  border: solid #ff3a2e 3px;
  font-size: 20px;
  width: 300px;
}

.mailchimp-submit {
  background: #ff3a2e;
  color: #ffff;
  border-radius: 0px 8px 8px 0px;
  font-style: normal;
  font-size: 20px;
  line-height: 28px;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
  height: 100%;
  width: 100%;
}

.sendingMessage {
  font-size: 20px;
  padding: 20px;
  background-color: #d4d4d4;
}

.errorMessage {
  font-size: 20px;
  padding: 20px;
  background-color: #fde4ea;
  line-height: 30px;
}

.successMessage {
  font-size: 20px;
  padding: 30px 20px;
  background-color: #ff3a2e;
  color: #ffff;
  line-height: 30px;
}

.titleMessage {
  font-size: 25px;
  font-weight: bold;
}

@include mobile {
  .mailchimp-input {
    width: auto;
  }
}
