@import "variables";

.mainImg {
  height: 100%;
  width: 100%;
}

.container {
  position: relative;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.position1-p4 {
  position: absolute;
  right: 15px;
  bottom: 160px;
}

.position2-p4 {
  position: absolute;
  right: 15px;
  bottom: 130px;
}
.position3-p4 {
  position: absolute;
  right: 15px;
  bottom: 100px;
}
.position4-p4 {
  position: absolute;
  right: 15px;
  bottom: 70px;
}

.position1 {
  position: absolute;
  right: 15px;
  bottom: 130px;
}

.position2 {
  position: absolute;
  right: 15px;
  bottom: 100px;
}
.position3 {
  position: absolute;
  right: 15px;
  bottom: 70px;
}

.line-color {
  background-color: #383838;
  color: white;
}

.line-white {
  background-color: white;
  color: #383838;
}

.paragraph {
  font-size: 21px;
  padding: 2px 10px 2px 10px;
}

.hotelName {
  color: white;
  position: absolute;
  left: 60px;
  bottom: 100px;
  font-size: 18px;
}

.hotelLocation {
  color: white;
  position: absolute;
  left: 60px;
  bottom: 70px;
  font-size: 18px;
}

@include mobile {
  .paragraph {
    font-size: 13px;
    padding: 5px 15px 5px 15px;
    white-space: nowrap;
    font-family: $subtitle-font;
  }

  .hotelDescription {
    display: flex;
    position: absolute;
    right: 20px;
    bottom: 40px;
  }

  .hotelName {
    font-size: 15px;
    position: static;
    margin-right: 5px;
  }

  .hotelLocation {
    font-size: 15px;
    position: static;
  }

  .position1-p4 {
    bottom: 190px;
  }
  .position2-p4 {
    bottom: 160px;
  }
  .position3-p4 {
    bottom: 130px;
  }
  .position4-p4 {
    bottom: 100px;
  }

  .position1 {
    position: absolute;
    right: 15px;
    bottom: 130px;
  }

  .position2 {
    position: absolute;
    right: 15px;
    bottom: 100px;
  }
  .position3 {
    position: absolute;
    right: 15px;
    bottom: 70px;
  }
}
