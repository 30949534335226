@import "variables";

.imgTextContainer {
  display: flex;
  min-height: 760px;
  background-color: #f8f6f2;
  justify-content: center;
  align-items: center;
}

.imageComponent {
  flex: 0 0 45%;
}

.textComponent {
  flex: 0 0 55%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 130px;
}

@include mobile {
  .imgTextContainer {
    display: flex;
    flex-direction: column;
  }

  .textComponent {
    padding: 40px;
  }
}
