.hidden {
  display: none !important;
}

.center-banner {
  display: flex;
  align-items: center;
}

.big-wrapper {
  padding: 0 35px;
  margin: 0 auto;

  @include mobile {
    padding: 0 15px;
  }
  @include min-medium-tablet {
    padding: 0 85px;
  }
  @include min-full-tablet {
    padding: 0 115px;
  }
  @include min-small-desktop {
    padding: 0 85px;
  }
  @include min-full-desktop {
    padding: 0 150px;
  }
}
.wrapper {
  padding: 0 25px;
  @include min-medium-tablet {
    padding: 0 40px;
  }
  @include min-full-tablet {
    padding: 0 40px;
    max-width: 845px;
    margin: 0 auto;
  }
  @include min-small-desktop {
    max-width: initial;
    padding: 0 50px 0 85px;
  }
  @include min-full-desktop {
    padding: 0 85px 0 150px;
    max-width: initial;
  }
}
.half-wrapper {
  &.responsive-multimedia-off {
    .half-multimedia {
      display: none;
    }
    @include min-small-desktop {
      .half-multimedia {
        display: block;
      }
    }
  }

  @include min-full-tablet {
    padding-top: 45px;
  }
  @include min-small-desktop {
    position: relative;
    padding-right: 50%;
    min-height: 100vh;
    .half-breadcrumbs {
      display: block;
      position: absolute;
      z-index: 3;
      top: 15px;
      right: 40px;
    }
  }
  @include min-full-desktop {
    padding-top: 70px;
    .half-breadcrumbs {
      top: 23px;
      right: 80px;
    }
  }
}
.half-multimedia {
  .wrap-half-multimedia {
    .half-multimedia-btn {
      display: none;
    }
  }
  @include min-small-desktop {
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    width: 50%;
    height: 90%;
    &.stop {
      position: absolute !important;
      bottom: 0 !important;
      top: auto !important;
      height: 100vh;
    }
    .wrap-half-multimedia {
      width: 100%;
      height: 100%;
      padding-top: 80px;
      .half-multimedia-btn {
        display: block;
        position: absolute;
        left: 50%;
        bottom: 13px;
        @include transform(translateX(-50%));
      }
    }
  }
  @include min-full-desktop {
    .wrap-half-multimedia {
      padding-top: 90px;
    }
  }
}

.half-multimedia-box {
  .wrap-multimedia-box {
    position: relative;
    padding-top: 66.66%;
    overflow: hidden;
    border-radius: 4px;
    .multimedia-box-element {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 635px;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
  @include min-small-desktop {
    height: 100%;
    width: 100%;
    padding: 50px;
    .wrap-multimedia-box {
      padding-top: initial;
      height: 100%;
      width: 100%;
    }
  }
  @include min-full-desktop {
    padding: 50px 85px;
  }
}

.intern-breadcrumbs {
  display: none;
  @include min-small-desktop {
    display: block;
    position: absolute;
    top: 100px;
    right: 50px;
  }
  @include min-full-desktop {
    top: 110px;
  }
}
.breadcrumbs-list {
  font-size: 0;
  .breadcrumbs-list-element {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    &.active {
      &:before {
        width: calc(100% - 24px);
      }
    }
    &:last-child {
      &:after {
        content: none;
      }
    }
    &:before {
      content: "";
      position: absolute;
      top: calc(100% + 2px);
      left: 50%;
      width: 0;
      height: 1px;
      background-color: #fff;
      @include transform(translateX(-50%));
      @include box-shadow;
      @include transition($Stransition);
    }
    &:after {
      content: "/";
      display: inline-block;
      vertical-align: middle;
      font-family: $corp-font1;
      font-size: 15px;
      font-weight: 500;
      color: #fff;
      @include space(20em);
      @include text-shadow;
    }
    .breadcrumbs-element-text {
      display: inline-block;
      vertical-align: middle;
      padding: 0 12px;
      font-family: $corp-font1;
      font-size: 15px;
      font-weight: 500;
      color: #fff;
      @include space(20em);
      @include text-shadow;
      @include transition($Stransition);
    }
    @include hovers {
      &:before {
        width: calc(100% - 24px);
      }
    }
  }
}
.color {
  .breadcrumbs-list {
    .breadcrumbs-list-element {
      &:before {
        background-color: $color-corp2;
        box-shadow: none;
      }
      &:after {
        color: $color-corp2;
        text-shadow: none;
      }
      .breadcrumbs-element-text {
        color: $color-corp2;
        text-shadow: none;
      }
      @include hovers {
        &:before {
          background-color: $color-corp2;
        }
        .breadcrumbs-element-text {
          color: $color-corp2;
        }
      }
    }
  }
}

//* lightbox.js options *//
.lightboxOverlay {
  background-color: #fff !important;
  opacity: 1 !important;
  cursor: zoom-out;
}
.lb-dataContainer {
  position: absolute;
  top: -50px;
  left: 35px;
  width: calc(100% - 70px) !important;
}
.lb-data .lb-details {
  width: 100% !important;
  text-align: center !important;
}
.lb-data .lb-number {
  position: absolute !important;
  bottom: 90px !important;
  left: 50% !important;
  @include transform(translateX(-50%));
  font-family: $corp-font1;
  font-size: 20px !important;
  color: $color-text !important;
  @include space(300em);
  font-weight: 400 !important;
  text-transform: uppercase;
}
.lightbox .lb-image {
  object-fit: cover;
  max-height: 768px !important;
}
.lb-data .lb-caption {
  font-family: $corp-font1;
  font-size: 20px !important;
  color: $color-text;
  @include space(300em);
  font-weight: 400 !important;
  text-transform: uppercase;
}
.lb-closeContainer {
  position: absolute;
  right: 0;
  top: 0;
  display: block;
  margin: 0 auto;
  z-index: 1;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 1px solid $color-corp1;
  cursor: pointer;
  @include transform(translateZ(0) scale(1));
  @include transition($Stransition);
  &:before {
    position: absolute;
    content: "\e910";
    top: 50%;
    left: 50%;
    @include transform(translateX(-50%) translateY(-50%) translateZ(0));
    font-family: "icomoon";
    font-size: 8px;
    color: $color-corp1;
    @include transition($Stransition);
  }
  @include hovers {
    &:hover {
      @include transform(translateZ(0) scale(1.14));
    }
  }
}
.lb-data .lb-close {
  background: none !important;
}
.lb-container {
  padding: 4px 90px !important;
}
.lb-nav {
  top: 50% !important;
  height: auto !important;
  width: calc(100% + 20px) !important;
  left: 50% !important;
  @include transform(translateY(-50%) translateX(-50%));
  overflow: hidden !important;
  padding: 10px;
  .lb-prev {
    float: left !important;
    display: block !important;
    width: 35px !important;
    height: 35px !important;
    background-color: #fff !important;
    margin-left: 0px !important;
    background: none !important;
    opacity: 1 !important;
    border-radius: 50% !important;
    border: 1px solid $color-corp1 !important;
    @include transition(all 0.2s ease-in-out);
    &:before {
      display: inline-block !important;
      content: "\e90a";
      font-family: "icomoon" !important;
      font-size: 8px !important;
      color: $color-corp1 !important;
      margin-top: 12px !important;
      margin-right: 2px !important;
      @include transform(rotate(90deg));
    }
    &:hover {
      @include transform(scale(1.14));
    }
  }
  .lb-next {
    float: right !important;
    display: block !important;
    width: 35px !important;
    height: 35px !important;
    background-color: #fff !important;
    margin-right: 0px !important;
    border-radius: 50% !important;
    background: none !important;
    opacity: 1 !important;
    border: 1px solid $color-corp1 !important;
    @include transition(all 0.2s ease-in-out);
    &:before {
      display: inline-block !important;
      content: "\e90a";
      font-family: "icomoon" !important;
      font-size: 8px !important;
      color: $color-corp1 !important;
      margin-top: 12px !important;
      margin-left: 2px !important;
      @include transform(rotate(-90deg));
    }
    &:hover {
      @include transform(scale(1.14));
    }
  }
}

//* slick.js options *//
.slick-slider {
  visibility: hidden;
  opacity: 0;
}
.slick-initialized {
  visibility: visible !important;
  opacity: 1 !important;
}
.slick-list,
.slick-track {
  height: 100%;
}

.input-element {
  display: inline-block;
  width: 100%;
  &.button {
    .wrap-input-element {
      .input-element-field {
        padding: 0 70px 0 25px;
      }
    }
  }
  &.select {
    cursor: pointer;
    .wrap-input-element {
      &:before {
        content: "\e90c";
        position: absolute;
        z-index: 2;
        right: 20px;
        top: 50%;
        @include transform(translateY(-50%));
        font-family: "icomoon";
        font-size: 10px;
        color: $color-corp1;
        pointer-events: none;
        @include transition($Stransition);
      }
      .input-element-field {
        padding: 0 70px 0 25px;
        cursor: pointer;
      }
    }
  }
  &.user {
    .wrap-input-element {
      &:before {
        content: url(../assets/img/icons/nombre.svg);
        position: absolute;
        z-index: 2;
        right: 20px;
        top: 50%;
        @include transform(translateY(-50%));
        font-family: "icomoon";
        font-size: 16px;
        color: $color-corp1;
        pointer-events: none;
        @include transition($Stransition);
      }
      .input-element-field {
        padding: 0 70px 0 25px;
      }
    }
  }
  &.company {
    .wrap-input-element {
      &:before {
        content: url(../assets/img/icons/empresa.svg);
        position: absolute;
        z-index: 2;
        right: 20px;
        top: 50%;
        @include transform(translateY(-50%));
        font-family: "icomoon";
        font-size: 16px;
        color: $color-corp1;
        pointer-events: none;
        @include transition($Stransition);
      }
      .input-element-field {
        padding: 0 70px 0 25px;
      }
    }
  }
  &.screen {
    .wrap-input-element {
      &:before {
        content: url(../assets/img/icons/web.svg);
        position: absolute;
        z-index: 2;
        right: 20px;
        top: 50%;
        @include transform(translateY(-50%));
        font-family: "icomoon";
        font-size: 16px;
        color: $color-corp1;
        pointer-events: none;
        @include transition($Stransition);
      }
      .input-element-field {
        padding: 0 70px 0 25px;
      }
    }
  }
  &.pass {
    .wrap-input-element {
      &:before {
        content: url(../assets/img/icons/empresa.svg);
        position: absolute;
        z-index: 2;
        right: 20px;
        top: 50%;
        @include transform(translateY(-50%));
        font-family: "icomoon";
        font-size: 16px;
        color: $color-corp1;
        pointer-events: none;
        @include transition($Stransition);
      }
      .input-element-field {
        padding: 0 70px 0 25px;
      }
    }
  }
  &.phone {
    .wrap-input-element {
      &:before {
        content: url(../assets/img/icons/telefono.svg);
        position: absolute;
        z-index: 2;
        right: 20px;
        top: 50%;
        @include transform(translateY(-50%));
        font-family: "icomoon";
        font-size: 16px;
        color: $color-corp1;
        pointer-events: none;
        @include transition($Stransition);
      }
      .input-element-field {
        padding: 0 70px 0 25px;
      }
    }
  }
  &.email {
    .wrap-input-element {
      &:before {
        content: url(../assets/img/icons/email.svg);
        position: absolute;
        z-index: 2;
        right: 20px;
        top: 50%;
        @include transform(translateY(-50%));
        font-family: "icomoon";
        font-size: 14px;
        color: $color-corp1;
        pointer-events: none;
        @include transition($Stransition);
      }
      .input-element-field {
        padding: 0 70px 0 25px;
      }
    }
  }
  &.must {
    position: relative;
    &:before {
      content: "";
      position: absolute;
      right: calc(100% + 14px);
      top: calc(50% - 3px);
      width: 6px;
      height: 6px;
      background-color: $color-corp2;
      border-radius: 50%;
    }
  }
  &.textarea {
    .wrap-input-element {
      height: 280px;
      .input-element-field {
        padding: 15px 25px;
      }
    }
  }
  .wrap-input-element {
    position: relative;
    border: 2px solid $color-corp1;
    border-radius: 1px;
    height: 55px;
    .input-element-text {
      position: absolute;
      z-index: 2;
      top: 0;
      left: 15px;
      font-family: $corp-font1;
      font-size: 15px;
      font-weight: 500;
      color: $color-corp1;
      @include space(30em);
      padding: 10px;
      background-color: #f8f6f2;
      pointer-events: none;
      @include transform(translateY(-50%));
      @include transition($Stransition);
    }
    .input-element-field {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      font-family: $corp-font1;
      font-size: 15px;
      font-weight: 500;
      color: rgba($color-corp1, 0.8);
      @include space(30em);
      padding: 0 25px;
      background-color: transparent;
      @include transition($Stransition);
      @include input-placeholder {
        font-family: $corp-font1;
        font-size: 15px;
        font-weight: 500;
        color: rgba($color-corp1, 0.8);
        @include space(30em);
      }
      &:focus {
        background-color: #f8f6f2;
      }
    }
  }
  @include hovers {
    .wrap-input-element {
      &:before {
        color: $color-corp2;
      }
      .input-element-text {
        color: $color-corp2;
      }
      .input-element-field {
        background-color: #f8f6f2;
      }
    }
  }
}
.checkbox-element {
  display: block;
  font-size: 0;
  cursor: pointer;
  .checkbox-input {
    display: none;
    &:checked {
      ~ .checkbox-icon {
        &:before {
          @include transform(translateY(-50%) translateX(-50%) scale(1));
          opacity: 1;
        }
      }
    }
  }
  .checkbox-icon {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 15px;
    height: 15px;
    border: 2px solid $color-corp1;
    margin-top: 4px;
    &:before {
      content: "\e902";
      position: absolute;
      top: 50%;
      left: 50%;
      @include transform(translateY(-50%) translateX(-50%) scale(0.5));
      opacity: 0;
      font-family: "icomoon";
      font-size: 15px;
      color: $color-corp1;
      @include transition($Stransition);
    }
  }

  .checkbox-text {
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 15px);
    font-family: $corp-font1;
    font-size: 15px;
    font-weight: 400;
    color: $color-text;
    @include space(40em);
    @include line(25, 15);
    padding-left: 8px;
    text-align: left;
    &.full {
      width: auto;
    }
    .checkbox-text-link {
      position: relative;
      display: inline-block;
      color: $color-text;
      @include transition($Stransition);
      &:before {
        content: "";
        position: absolute;
        @include transform(translateY(5px));
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        background-color: $color-text;
        @include transition($Stransition);
      }
      @include hovers {
        color: $color-corp1;
        &:before {
          background-color: $color-corp1;
          @include transform(translateY(3px));
        }
      }
    }
  }
}

.btn-close {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  border: 2px solid $color-corp1;
  @include transition($Stransition);
  cursor: pointer;
  &.btn-white {
    border-color: #fff;
    @include box-shadow;
    &:before {
      color: #fff;
      @include text-shadow;
    }
    &:after {
      background-color: #fff;
    }
  }
  &:before {
    content: "\e902";
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    @include transform(translateY(-50%) translateX(-50%));
    font-family: "icomoon";
    font-size: 20px;
    color: $color-corp1;
    @include transition($Stransition);
  }
  @include hovers {
    background-color: $color-corp1;
    border-color: #fff;
    &.btn-white {
      &:before {
        color: $color-corp1;
      }
    }
    &:before {
      color: #fff;
    }
  }
  @include min-medium-tablet {
    width: 44px;
    height: 44px;
  }
}

//* CORP-COLOR BUTTON *//
.btn-corp {
  position: relative;
  display: inline-block;
  text-align: center;
  margin-right: 10px;
  margin-bottom: 10px;
  .btn-corp-text {
    cursor: pointer;
    position: relative;
    z-index: 1;
    display: block;
    padding: 13px 10px;
    font-family: $subtitle-font;
    font-size: 20px;
    font-weight: 500;
    color: #ff3a2e;
    @include space(20em);
    border: 2px solid #ff3a2e;
    border-radius: 4px;
    background-color: transparent;
    min-width: 240px;
    background-color: #f8f6f2;
    @include transition($Stransition);
    @include hovers {
      background-color: #ff3a2e;
      border-color: transparent;
      color: #fff;
    }
  }
}

.btn-corp2 {
  position: relative;
  display: inline-block;
  text-align: center;
  margin: 10px 10px 0px 0px;
  .btn-corp-text {
    cursor: pointer;
    position: relative;
    z-index: 1;
    display: block;
    padding: 13px 10px;
    font-family: $subtitle-font;
    font-size: 20px;
    font-weight: 500;
    color: #fff;
    @include space(20em);
    border: 2px solid #fff;
    border-radius: 4px;
    background-color: transparent;
    min-width: 240px;
    background-color: #ff3a2e;
    @include transition($Stransition);
    @include hovers {
      background-color: #f8f6f2;
      border-color: transparent;
      color: #ff3a2e;
    }
    @include mobile {
      font-size: 20px;
    }
  }
}

.spacer {
  padding-top: 80px;
  background-color: #f8f6f2;
}

.show-error-form {
  input:invalid:required {
    border: 2px solid red;
  }
  select:invalid:required {
    border: 2px solid red;
  }
  .wrap-input-element-invalid {
    border: none !important;
  }
  .checkbox-icon-invalid {
    border: 2px solid red;
  }
}
