@import "variables";
@import "reset";
@import "global";
@import "menu";

.intern {
  @include min-full-tablet {
    padding-top: 80px;
  }
}

.aside-action {
  @include full-tablet {
    position: fixed;
    z-index: 105;
    left: 50%;
    bottom: 8px;
    @include transform(translateX(-50%));
    .aside-action-text {
      display: none;
    }
    .aside-action-btn {
      display: block;
      width: 130px;
      padding: 9px 0;
      border-radius: 4px;
      border: 2px solid $color-corp1;
      text-align: center;
      .action-btn-text {
        font: 500 16px/1 $corp-font1;
        color: $color-corp1;
      }
    }
    @include mobile {
      .aside-action-btn {
        width: 105px;
        .action-btn-text {
          font-size: 14px;
        }
      }
    }
    @include min-medium-tablet {
      bottom: 15px;
      .aside-action-btn {
        width: 240px;
        padding: 13px 0;
        .action-btn-text {
          font-size: 20px;
        }
      }
    }
  }
  @include min-full-tablet {
    position: fixed;
    z-index: 10;
    right: 40px;
    bottom: 45px;
    width: 210px;
    .wrap-aside-action {
      padding: 15px 25px;
      border-radius: 3px;
      background-color: rgba(#000000, 0.5);
    }
    .aside-action-text {
      font: 500 16px/1.1 $corp-font1;
      @include space(50em);
      color: #fff;
      padding-bottom: 15px;
    }
    .aside-action-btn {
      display: block;
      padding: 9px 0;
      border-radius: 4px;
      background-color: $color-corp2;
      text-align: center;
      @include transition($Stransition);
      .action-btn-text {
        font: 500 18px/1 $corp-font1;
        @include space(20em);
        color: #fff;
      }
      @include hovers {
        background-color: $color-corp2-hover;
      }
    }
  }
}

.home-module {
  .wrap-home-module {
    position: relative;
    height: calc(100vh - 55px);
    min-height: 612px;
  }
  @include mobile {
    .wrap-home-module {
      min-height: 513px;
    }
  }
  @include min-medium-tablet {
    .wrap-home-module {
      height: calc(100vh - 80px);
    }
  }
  @include min-full-tablet {
    .wrap-home-module {
      height: 100vh;
    }
  }
}
.home-module-logo {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  .wrap-home-logo {
    padding: 40px 0 0;
    text-align: center;
  }
  .home-logo-graphic {
    display: inline-block;
    width: 240px;
    height: auto;
    filter: drop-shadow(2px 2px 2px rgba($color-text, 0.4));
    -o-filter: drop-shadow(2px 2px 2px rgba($color-text, 0.4));
    -ms-filter: drop-shadow(2px 2px 2px rgba($color-text, 0.4));
    -webkit-filter: drop-shadow(2px 2px 2px rgba($color-text, 0.4));
  }
  @include min-medium-tablet {
    .wrap-home-logo {
      padding: 50px 0 0;
    }
    .home-logo-graphic {
      width: 385px;
    }
  }
  @include min-full-tablet {
    display: none;
  }
}
.home-module-slider {
  position: absolute !important;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.home-slider-element {
  height: 100%;
  .wrap-slider-element {
    position: relative;
    height: 100%;
    overflow: hidden;
  }
  .slider-element-img {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(#010101, 0.2);
    }
    @include min-small-desktop {
      display: none;
    }
  }
  .slider-element-video {
    display: none;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    &:before {
      content: "";
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(#000, 0.15);
    }
    .slider-element-video_element {
      position: absolute;
      left: 50%;
      top: 50%;
      min-height: 100%;
      min-width: 100%;
      @include transform(translateY(-50%) translateX(-50%));
    }
    @include min-small-desktop {
      display: block;
    }
  }
  .slider-element-info {
    display: none;
  }
  .slider-element-info {
    display: block;
    position: absolute;
    z-index: 2;
    left: 35px;
    bottom: 45px;
    padding-left: 25px;
    &:before {
      content: "";
      position: absolute;
      top: 7px;
      left: 0;
      width: 15px;
      height: 1px;
      background-color: $color-corp2;
    }
    .slider-info-text {
      font: 500 15px/1.1 $corp-font1;
      @include space(40em);
      color: #fff;
      @include text-shadow;
    }
    .slider-info-subtext {
      font: 400 12px/1.1 $corp-font1;
      @include space(20em);
      color: #fff;
      @include text-shadow;
      padding-top: 5px;
    }
    @include full-tablet {
      left: 0;
      bottom: 250px;
      width: 100%;
      text-align: center;
      padding-left: 0;
      &:before {
        content: none;
      }
      .slider-info-subtext {
        width: 260px;
        display: inline-block;
        border-bottom: 1px solid $color-corp1;
        padding-bottom: 15px;
      }
    }
    @include medium-tablet {
      bottom: 205px;
    }
    @include small-tablet {
      bottom: 80px;
      .slider-info-text {
        width: 260px;
        display: inline-block;
        border-top: 1px solid $color-corp1;
        padding-top: 33px;
      }
      .slider-info-subtext {
        padding-bottom: 0;
        border-bottom: none;
      }
    }
  }
}
.home-module-info {
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0;
  width: 100%;
  .wrap-home-info {
    padding: 0 45px 20px;
    text-align: center;
    @include hovers {
      .home-info-text {
        opacity: 0;
      }
      .home-info-scroll {
        background-color: $color-corp2;
        border-color: transparent;
        &:before {
          color: #fff;
        }
        .info-scroll-tag {
          @include transform(translateX(-50%) translateY(0) scale(1));
          opacity: 1;
        }
      }
    }
  }
  .home-info-title {
    font: 500 30px/1 $corp-font-titles;
    @include space(20em);
    color: #fff;
    @include text-shadow;
    padding-bottom: 12px;
  }
  .home-info-text {
    font: 400 16px/1.2 $corp-font1;
    @include space(40em);
    color: #fff;
    @include text-shadow;
    padding-bottom: 15px;
    @include transition($Stransition);
  }
  .home-info-scroll {
    position: relative;
    display: inline-block;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    border: 3px solid #fff;
    @include box-shadow;
    cursor: pointer;
    @include transition($Stransition);
    &:before {
      position: absolute;
      top: 50%;
      left: 50%;
      @include transform(translateY(-45%) translateX(-50%));
      font-size: 10px;
      color: #fff;
      @include text-shadow;
      @include transition($Stransition);
    }
    .info-scroll-tag {
      position: absolute;
      left: 50%;
      bottom: calc(100% + 20px);
      width: 130px;
      @include transform(translateX(-50%) translateY(10px) scale(0.8));
      opacity: 0;
      padding: 6px 0;
      text-align: center;
      background-color: $color-corp2;
      border-radius: 2px;
      font: 500 13px/1 $corp-font1;
      @include space(40em);
      color: #fff;
      @include transition($Stransition);
      &:after {
        content: "\e90e";
        position: absolute;
        top: calc(100% - 2px);
        left: calc(50% - 4px);
        font-family: "icomoon";
        font-size: 8px;
        color: $color-corp2;
      }
    }
  }
  @include small-tablet {
    .home-info-text {
      padding-bottom: 115px;
    }
  }
  @include min-medium-tablet {
    .wrap-home-info {
      padding: 0 65px 25px;
    }
    .home-info-title {
      font-size: 50px;
    }
    .home-info-text {
      font-size: 20px;
      padding-bottom: 20px;
    }
  }
  @include min-full-tablet {
    .wrap-home-info {
      padding: 0 270px 45px;
    }
    .home-info-text {
      line-height: 1.2;
    }
  }
  @include min-full-desktop {
    .home-info-title {
      font-size: 62px;
    }
  }
}

.gallery-info-list {
  .gallery-info {
    &:nth-child(even) {
      background-color: $color-background;
    }
  }
}
.gallery-info {
  .wrap-gallery-info {
    padding: 45px 0;
  }
  @include min-medium-tablet {
    .wrap-gallery-info {
      padding: 60px 0;
    }
  }
  @include min-full-tablet {
    @include small-desktop {
      .wrap-gallery-info {
        max-width: 600px;
        margin: 0 auto;
      }
    }
  }
  @include min-small-desktop {
    &.var-info-right {
      .gallery-info-multimedia {
        float: left;
      }
      .gallery-info-content {
        float: right;
        .wrap-gallery-content {
          padding-left: 60px;
        }
      }
    }
    &.var-info-left {
      .gallery-info-multimedia {
        float: right;
      }
      .gallery-info-content {
        float: left;
        .wrap-gallery-content {
          padding-right: 60px;
        }
      }
    }
    .wrap-gallery-info {
      padding: 90px 0;
      &:before,
      &:after {
        content: "";
        position: relative;
        display: block;
        clear: both;
      }
    }
  }
  @include min-full-desktop {
    &.var-info-right {
      .gallery-info-content {
        .wrap-gallery-content {
          padding-left: 120px;
        }
      }
    }
    &.var-info-left {
      .gallery-info-content {
        .wrap-gallery-content {
          padding-right: 120px;
        }
      }
    }
    .wrap-gallery-info {
      padding: 120px 0;
    }
  }
}
.gallery-info-multimedia {
  .wrap-gallery-multimedia {
    position: relative;
    padding-top: 62%;
  }
  .gallery-multimedia-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
  }
  @include min-medium-tablet {
    .wrap-gallery-multimedia {
      padding-top: 60%;
    }
  }
  @include min-full-tablet {
    .wrap-gallery-multimedia {
      padding-top: 65%;
    }
  }
  @include min-small-desktop {
    width: 45%;
  }
  @include min-full-desktop {
    width: 50%;
  }
}
.gallery-info-content {
  .wrap-gallery-content {
    padding-top: 15px;
  }
  .gallery-content-title {
    font: 500 42px/1 $corp-font-titles;
    color: $color-titles;
    padding-bottom: 15px;
  }
  .gallery-content-subtitle {
    font: 500 16px/1 $corp-font1;
    @include space(50em);
    @include line(23, 16);
    text-transform: uppercase;
    color: $color-subtitles;
    padding-bottom: 10px;
  }
  .gallery-content-text {
    font: 400 19px/1 $corp-font1;
    @include space(20em);
    @include line(26, 19);
    color: $color-text;
  }
  @include min-medium-tablet {
    .wrap-gallery-content {
      padding-top: 25px;
    }
    .gallery-content-title {
      font-size: 80px;
      padding-bottom: 20px;
    }
    .gallery-content-subtitle {
      font-size: 20px;
      @include space(100em);
      padding-bottom: 15px;
    }
    .gallery-content-text {
      font-size: 24px;
      @include line(32, 24);
    }
  }
  @include min-small-desktop {
    width: 55%;
    font-size: 0;
    &:before {
      content: "";
      position: relative;
      display: inline-block;
      vertical-align: middle;
      width: 0;
      padding-top: 53.15%;
      opacity: 0;
      pointer-events: none;
    }
    .wrap-gallery-content {
      display: inline-block;
      vertical-align: middle;
      padding-top: 0;
    }
    .gallery-content-text,
    .gallery-content-subtitle {
      max-width: 600px;
    }
    @include full-desktop {
      .gallery-content-title {
        font-size: 52px;
        padding-bottom: 15px;
      }
      .gallery-content-subtitle {
        font-size: 16px;
        @include space(50em);
        padding-bottom: 10px;
      }
      .gallery-content-text {
        font-size: 19px;
        @include line(26, 19);
      }
    }
  }
  @include min-full-desktop {
    width: 50%;
    &:before {
      padding-top: 65%;
    }
  }
}

.info-banner {
  background-color: $color-corp1;
  .wrap-info-banner {
    padding: 40px 0;
    text-align: center;
  }
  .info-banner-title {
    font: 500 38px/1 $corp-font-titles;
    @include line(50, 42);
    color: #fff;
  }
  .info-banner-img {
    display: inline-block;
    width: 100%;
    height: auto;
    margin: 30px 0;
  }
  .info-banner-subtitle {
    font: 500 18px/1 $corp-font1;
    @include space(100em);
    @include line(26, 18);
    color: #fff;
    text-transform: uppercase;
    padding-bottom: 10px;
  }
  .info-banner-text {
    font: 400 19px/1 $corp-font1;
    @include space(20em);
    @include line(26, 19);
    color: #fff;
    padding-bottom: 20px;
  }
  .info-banner-btn {
    display: inline-block;
    .banner-btn-text {
      position: relative;
      font: 400 22px/1 $corp-font1;
      @include space(20em);
      color: $color-corp2;
      padding-bottom: 5px;
      border-bottom: 1px solid $color-corp2;
      @include transition($Stransition);
      &:after {
        content: "\e906";
        position: absolute;
        left: calc(100% + 10px);
        top: 10px;
        font-family: "icomoon";
        font-size: 8px;
        color: $color-corp2;
        @include transition($Stransition);
      }
    }
    @include hovers {
      .banner-btn-text {
        color: #fff;
        border-color: #fff;
        &:after {
          left: calc(100% + 12px);
          color: #fff;
        }
      }
    }
  }
  @include min-medium-tablet {
    .wrap-info-banner {
      padding: 55px 0;
    }
    .info-banner-title {
      font-size: 76px;
    }
    .info-banner-img {
      max-width: 555px;
      margin: 50px 0;
    }
    .info-banner-subtitle {
      font-size: 22px;
      padding-bottom: 20px;
    }
    .info-banner-text {
      font-size: 24px;
      @include space(40em);
      padding-bottom: 30px;
    }
  }
  @include min-full-tablet {
    .wrap-info-banner {
      width: 790px;
      margin: 0 auto;
    }
    .info-banner-title {
      font-size: 86px;
    }
  }
  @include min-small-desktop {
    .wrap-info-banner {
      width: 820px;
      padding: 90px 0;
    }
  }
  @include min-full-desktop {
    .wrap-info-banner {
      padding: 120px 0;
    }
    .info-banner-title {
      font-size: 96px;
    }
  }
}

.info-data {
  .wrap-info-data {
    padding: 40px 0;
    text-align: center;
  }
  .info-data-title {
    font: 500 42px/1 $corp-font-titles;
    color: $color-corp2;
  }
  @include min-medium-tablet {
    .wrap-info-data {
      padding: 70px 0;
    }
    .info-data-title {
      font-size: 76px;
      padding-bottom: 10px;
    }
  }
  @include min-small-desktop {
    .wrap-info-data {
      padding: 90px 0;
    }
  }
  @include min-full-desktop {
    .wrap-info-data {
      padding: 110px 0;
    }
  }
}
.info-data-list {
  font-size: 0;
  @include min-medium-tablet {
    margin: 0 -35px;
  }
}
.info-data-element {
  display: inline-block;
  width: 100%;
  .wrap-data-element {
    padding: 40px 0 0;
  }
  .data-element-num {
    font: 500 80px/1 $corp-font-titles;
    color: $color-corp1;
    padding-bottom: 10px;
    border-bottom: 1px solid $color-lines;
    .highlight {
      color: $color-corp2;
    }
  }
  .data-element-title {
    padding-top: 20px;
    font: 500 18px/1 $corp-font1;
    @include space(100em);
    color: $color-corp1;
    text-transform: uppercase;
  }
  .data-element-text {
    padding-top: 10px;
    font: 400 14px/1 $corp-font1;
    @include space(10em);
    color: $color-text;
  }
  @include min-medium-tablet {
    vertical-align: top;
    padding: 0 35px 0;
    width: 330px;
    .wrap-data-element {
      padding: 45px 0 0;
    }
  }
  @include min-full-tablet {
    width: 370px;
  }
  @include min-full-desktop {
    width: 470px;
    .wrap-data-element {
      padding: 60px 0 0;
    }
    .data-element-num {
      font-size: 100px;
    }
  }
}

.comments-banner {
  background-color: $color-corp1;
  overflow: hidden;
  .wrap-comments-banner {
    padding: 40px 0;
    text-align: center;
  }
  .comments-banner-title {
    font: 500 32px/1 $corp-font1;
    color: $color-corp2;
  }
  @include min-medium-tablet {
    .wrap-comments-banner {
      padding: 70px 0;
    }
    .comments-banner-title {
      font-size: 38px;
    }
  }
  @include min-full-desktop {
    .wrap-comments-banner {
      padding: 100px 0;
    }
  }
}
.comments-banner-box {
  padding: 35px 5px 0;
  .slick-btn {
    position: absolute;
    z-index: 1;
    top: 30%;
    font-size: 30px;
    color: $color-corp2;
    cursor: pointer;
    &.slick-prev {
      left: -25px;
    }
    &.slick-next {
      right: -25px;
    }
  }
  @include min-medium-tablet {
    padding: 50px 0 0;
    .slick-btn {
      &.slick-prev {
        left: -60px;
        &:before {
          content: "\e900";
        }
      }
      &.slick-next {
        right: -60px;
        &:before {
          content: "\e901";
        }
      }
    }
  }
  @include min-full-tablet {
    .slick-list {
      padding: 0 150px;
      margin: 0 -115px;
    }
    .slick-btn {
      &.slick-prev {
        left: 15px;
      }
      &.slick-next {
        right: 15px;
      }
    }
  }
  @include min-small-desktop {
    .slick-list {
      padding: 0 250px;
      margin: 0 -85px;
    }
    .slick-btn {
      width: 68px;
      height: 68px;
      border-radius: 50%;
      @include transition($Stransition);
      &:before {
        position: absolute;
        top: 50%;
        left: 50%;
        @include transform(translateY(-50%) translateX(-50%));
        @include transition($Stransition);
      }
      &.slick-prev {
        left: 135px;
      }
      &.slick-next {
        right: 135px;
      }
      @include hovers {
        background-color: $color-corp2;
        &:before {
          color: #fff;
        }
      }
    }
  }
  @include min-full-desktop {
    .slick-list {
      padding: 0 450px;
      margin: 0 -150px;
    }
    .slick-btn {
      &.slick-prev {
        left: 275px;
      }
      &.slick-next {
        right: 275px;
      }
    }
  }
}
.comments-banner-element {
  .comments-element-text {
    font: 500 26px/1 $corp-font-titles;
    @include line(32, 26);
    color: #fff;
  }
  @include min-medium-tablet {
    .comments-element-text {
      font-size: 35px;
      @include line(45, 35);
    }
  }
  @include min-full-tablet {
    .wrap-comments-element {
      padding: 0 50px;
    }
  }
  @include min-full-desktop {
    .comments-element-text {
      font-size: 45px;
    }
  }
}
.comments-element-user {
  padding-top: 35px;
  .comments-user-icon {
    position: relative;
    display: inline-block;
    width: 68px;
    height: 68px;
    border-radius: 50%;
    border: 4px solid $color-corp2;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    &:before {
      position: absolute;
      top: 50%;
      left: 50%;
      @include transform(translateY(-50%) translateX(-50%));
      font-size: 28px;
      color: #fff;
    }
  }
  .comments-user-name {
    padding-top: 15px;
    font: 500 20px/1 $corp-font1;
    @include line(32, 26);
    color: #fff;
  }
  .comments-user-info {
    padding-top: 5px;
    font: 400 14px/1 $corp-font1;
    @include space(10em);
    @include line(18, 14);
    color: rgba(#fff, 0.7);
  }
  @include min-medium-tablet {
    max-width: 260px;
    margin: 0 auto;
  }
}

.inlinks-banner-div-white {
  background-color: #f8f6f2;
  opacity: 0.8;
  width: 100%;
  min-height: 760px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -100;
}

.inlinks-banner-div-color {
  background-color: #ff3a2e;
  width: 100%;
  min-height: 760px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;

  @include mobile {
    min-height: 396px;
  }
}

.inlinks-banner {
  background-size: cover;
  min-height: 760px;
  position: relative;
  display: flex;
  justify-content: center;

  @include mobile {
    min-height: 0px;
  }

  &.var-lighter {
    background-color: $color-background;
    .inlinks-banner-title {
      color: $color-titles;
    }
    .inlinks-banner-text {
      color: $color-text;
      opacity: 1;
    }
  }
  &:not(.var-lighter) {
    .btn-corp {
      .btn-corp-text {
        color: $color-corp2;
        border-color: $color-corp2;
        background-color: transparent;
        @include hovers {
          color: white;
          background-color: $color-corp2;
        }
      }
    }
  }
  .wrap-inlinks-banner {
    padding: 60px 0;
    text-align: center;
  }
  .inlinks-banner-title {
    white-space: pre-line;
    font: 500 30px/1 $corp-font-titles;
    @include line(50, 42);
    color: $color-corp1;
    position: relative;
    z-index: 1;
    @include mobile {
      font-size: 40px;
    }
  }

  .inlinks-banner-title-white {
    font: 500 70px/1 $corp-font-titles;
    @include line(50, 42);
    color: white;
    position: relative;
    z-index: 1;
    white-space: pre-line;
    @include mobile {
      font-size: 40px;
    }
  }

  .inlinks-banner-subtitle {
    margin-top: 20px;
    color: $color-corp2;
    position: relative;
    z-index: 1;
    font: 500 21px $subtitle-font;
  }

  .inlinks-banner-text {
    font-size: 20px;
    @include line(26, 19);
    padding-top: 15px;
    color: $color-corp1;
    white-space: pre-line;
    small {
      font-size: 14px;
      line-height: 1;
    }
  }

  .banner-text-mobile {
    @include mobile {
      margin: 0px 50px;
    }
  }

  .inlinks-banner-text-white {
    font: 400 25px/1 $corp-font1;
    @include line(26, 19);
    padding-top: 15px;
    color: white;
    opacity: 0.99;
    white-space: pre-line;
    small {
      font-size: 14px;
      line-height: 1;
    }
    @include mobile {
      font-size: 20px;
    }
  }

  @include min-medium-tablet {
    .wrap-inlinks-banner {
      padding: 120px 0;
    }
    .inlinks-banner-title {
      font-size: 78px;
    }
    .inlinks-banner-text {
      font-size: 25px;
      padding-top: 25px;
    }
  }
  @include min-full-tablet {
    .wrap-inlinks-banner {
      width: 800px;
      margin: 0 auto;
    }
    .inlinks-banner-title {
      font-size: 70px;
    }
    .inlinks-banner-text {
      max-width: 700px;
      margin: 0 auto;
    }
  }
  @include min-full-desktop {
    .inlinks-banner-title {
      font-size: 80px;
      position: relative;
      z-index: 1;
    }

    .inlinks-banner-title-white {
      font-size: 80px;
      position: relative;
      z-index: 1;
      color: white;
    }
  }
}
.inlinks-banner-btns {
  padding-top: 10px;
  font-size: 0;
  margin: 0 -13px;
  .banner-btns-element {
    display: inline-block;
    vertical-align: top;
    padding: 20px 13px 0;
  }

  @include min-medium-tablet {
    padding-top: 20px;
    @include small-desktop {
      .banner-btns-element {
        .btn-corp-text {
          min-width: 180px;
        }
      }
    }
  }
}

.main-footer {
  .wrap-main-footer {
    position: relative;
    padding: 70px 0 90px;
  }
  @include min-medium-tablet {
    .wrap-main-footer {
      padding: 70px 0 120px;
    }
  }
  @include min-full-tablet {
    .wrap-main-footer {
      padding: 70px 0 50px;
      border-bottom: 3px solid $color-corp2;
    }
  }
  @include min-small-desktop {
    .wrap-main-footer {
      &:before,
      &:after {
        content: "";
        display: block;
        clear: both;
      }
    }
  }
}
.main-footer-top {
  position: absolute;
  top: 0;
  left: 50%;
  @include transform(translateX(-50%) translateY(-50%));
  padding: 12px;
  background-color: #fff;
  border-radius: 50%;
  cursor: pointer;
  .footer-top-tag {
    display: none;
  }
  .footer-top-icon {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid $color-corp1;
    @include transition($Stransition);
    &:before {
      position: absolute;
      top: 50%;
      left: 50%;
      @include transform(translateY(-50%) translateX(-50%));
      font-size: 10px;
      color: $color-corp1;
      @include transition($Stransition);
    }
  }
  @include min-full-tablet {
    left: auto;
    right: 0;
    @include transform(translateY(-50%));
  }
  @include min-small-desktop {
    .footer-top-tag {
      display: block;
      position: absolute;
      bottom: 100%;
      left: calc(50% - 22.5px);
      width: 45px;
      font: 500 13px/1 $corp-font1;
      color: #fff;
      @include space(40em);
      padding: 7px 0;
      text-align: center;
      background-color: $color-corp2;
      border-radius: 2px;
      @include transition($Stransition);
      opacity: 0;
      pointer-events: none;
      @include transform(translateY(0) scale(0.8));
      &:after {
        content: "\e90e";
        position: absolute;
        left: calc(50% - 4px);
        top: calc(100% - 2px);
        font-family: "icomoon";
        font-size: 8px;
        color: $color-corp2;
      }
    }
    @include hovers {
      .footer-top-tag {
        opacity: 1;
        @include transform(translateY(-12px) scale(1));
      }
      .footer-top-icon {
        background-color: $color-corp2;
        border-color: transparent;
        &:before {
          color: #fff;
        }
      }
    }
  }
}
.main-footer-info {
  .wrap-footer-info {
    position: relative;
    padding-bottom: 10px;
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: $color-lines;
    }
  }
  @include min-medium-tablet {
    .wrap-footer-info {
      font-size: 0;
      padding-bottom: 20px;
      margin: 0 -35px;
      &:after {
        left: 35px;
        width: calc(100% - 70px);
      }
    }
  }
  @include min-small-desktop {
    float: left;
    width: 640px;
    .wrap-footer-info {
      padding: 0;
      &:after {
        content: none;
      }
    }
    @include full-desktop {
      width: 50%;
      .wrap-footer-info {
        margin: 0 -15px;
        padding-right: 15px;
      }
    }
  }
}
.footer-info-box {
  .wrap-info-box {
    padding-bottom: 25px;
  }
  .info-box-title {
    font: 500 20px/1 $corp-font1;
    color: $color-corp2;
    @include space(20em);
    padding-bottom: 15px;
  }
  .info-box-text {
    display: block;
    font: 400 20px/1 $corp-font1;
    color: $color-text;
    padding: 5px 0;
  }
  @include min-medium-tablet {
    display: inline-block;
    vertical-align: top;
    width: 50%;
    .wrap-info-box {
      padding: 0 35px 40px;
    }
  }
  @include min-small-desktop {
    a.info-box-text {
      display: table;
      position: relative;
      @include transition($Stransition);
      &:after {
        content: "";
        position: absolute;
        bottom: 2px;
        left: 0;
        width: 0;
        height: 1px;
        background-color: $color-corp2;
        @include transition($Stransition);
      }
      @include hovers {
        color: $color-corp2;
        &:after {
          width: 100%;
        }
      }
    }
    @include full-desktop {
      .wrap-info-box {
        padding: 0 15px 40px;
      }
    }
  }
}
.main-footer-links {
  .wrap-footer-links {
    padding: 40px 0 0;
  }
  @include min-medium-tablet {
    .wrap-footer-links {
      padding: 60px 0 40px;
      margin: 0 -22px;
      font-size: 0;
    }
  }
  @include min-small-desktop {
    float: right;
    width: 590px;
    .wrap-footer-links {
      padding: 0;
    }
    @include full-desktop {
      width: 50%;
      .wrap-footer-links {
        margin: 0 -10px;
        padding-left: 10px;
      }
    }
  }
}
.footer-links-box {
  .wrap-links-box {
    padding-bottom: 35px;
  }
  .links-box-title {
    font: 500 20px/1 $corp-font1;
    color: $color-corp2;
    @include space(20em);
    padding-bottom: 15px;
  }
  .links-box-text {
    display: block;
    font: 400 18px/1 $corp-font1;
    color: $color-text;
    @include space(20em);
    padding: 8px 0;
  }
  @include min-medium-tablet {
    display: inline-block;
    vertical-align: top;
    width: 33.33%;
    .wrap-links-box {
      padding: 0 22px;
    }
  }
  @include min-small-desktop {
    .links-box-text {
      display: table;
      position: relative;
      @include transition($Stransition);
      &:after {
        content: "";
        position: absolute;
        bottom: 2px;
        left: 0;
        width: 0;
        height: 1px;
        background-color: $color-corp2;
        @include transition($Stransition);
      }
      @include hovers {
        color: $color-corp2;
        &:after {
          width: 100%;
        }
      }
    }
    @include full-desktop {
      .wrap-links-box {
        padding: 0 10px;
      }
    }
  }
}
.main-footer-sign {
  .footer-sign-text {
    font: 700 14px/1 $corp-font1;
    color: $color-corp1;
    @include space(20em);
    text-transform: uppercase;
    padding: 10px 0;
    &:nth-child(2) {
      border-top: 1px solid $color-lines;
    }
  }
  @include min-medium-tablet {
    .wrap-footer-sign {
      font-size: 0;
      margin: 0 -20px;
    }
    .footer-sign-text {
      display: inline-block;
      vertical-align: middle;
      border-left: 1px solid $color-lines;
      padding: 0 20px;
      &:first-child {
        border-left: none;
      }
      &:nth-child(2) {
        border-top: none;
      }
    }
  }
  @include min-small-desktop {
    clear: both;
    .wrap-footer-sign {
      padding-top: 40px;
    }
  }
}

.responsive-header {
  .wrap-responsive-header {
    text-align: center;
    padding: 8px 0 7px;
    border-bottom: 1px solid $color-corp2;
  }
  .responsive-header-logo {
    display: inline-block;
    width: 165px;
    height: auto;
  }
  @include min-medium-tablet {
    .wrap-responsive-header {
      padding: 17px 0 16px;
    }
    .responsive-header-logo {
      width: 190px;
    }
  }
  @include min-full-tablet {
    display: none;
  }
}

.intro-header {
  position: relative;
  background-color: $color-corp1;
  &.var-menu-space {
    .wrap-intro-header {
      padding-bottom: 0;
    }
    @include min-medium-tablet {
      .wrap-intro-header {
        padding-bottom: 30px;
      }
    }
  }
  .wrap-intro-header {
    padding: 20px 0;
  }
  .intro-header-title {
    font: 500 36px/1 $corp-font-titles;
    color: #fff;
    @include space(10em);
  }
  .intro-header-text {
    font: 400 20px/1 $corp-font1;
    color: $color-corp2;
    @include space(20em);
    @include line(24, 20);
    padding-top: 10px;
  }
  @include min-medium-tablet {
    .big-wrapper {
      padding: 0 50px;
    }
    .wrap-intro-header {
      padding: 45px 0;
    }
    .intro-header-title {
      font-size: 50px;
    }
    .intro-header-text {
      font-size: 28px;
      @include space(40em);
      @include line(34, 28);
    }
  }
  @include min-full-tablet {
    .big-wrapper {
      padding: 0 80px;
    }
  }
  @include min-full-desktop {
    .big-wrapper {
      padding: 0 150px;
    }
    .intro-header-text {
      font-size: 34px;
    }
  }
}
.intro-header-breadcrumbs {
  display: none;
  @include min-full-tablet {
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;
    .wrap-header-breadcrumbs {
      font-size: 0;
    }
    .header-breadcrumbs-element {
      position: relative;
      display: inline-block;
      vertical-align: top;
      font: 500 14px/1 $corp-font1;
      color: $color-corp2;
      @include space(80em);
      padding: 0 20px 4px;
      @include transition($Stransition);
      &.active {
        &:after {
          width: calc(100% - 40px);
        }
      }
      &:before {
        content: "/";
        position: absolute;
        left: 0;
        top: 3px;
        font: 500 12px/1 $corp-font1;
        color: $color-corp2;
      }
      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 20px;
        width: 0;
        height: 1px;
        background-color: $color-corp2;
        @include transition($Stransition);
      }
      &:first-child {
        &:before {
          content: none;
        }
      }
      @include hovers {
        &:after {
          width: calc(100% - 40px);
        }
      }
    }
  }
}

.gallery-details {
  &.var-info-left {
    @include min-small-desktop {
      .gallery-details-multimedia {
        right: 0;
        .wrap-details-multimedia {
          padding-right: 80px;
        }
        .details-multimedia-btn {
          left: calc(50% - 30px);
        }
      }
    }
    @include min-full-desktop {
      .gallery-details-multimedia {
        .wrap-details-multimedia {
          padding-right: 150px;
        }
        .details-multimedia-btn {
          left: calc(50% - 75px);
        }
      }
    }
  }
  &.var-info-right {
    @include min-small-desktop {
      .gallery-details-content {
        margin: 0 0 0 auto;
      }
      .gallery-details-multimedia {
        left: 0;
        .wrap-details-multimedia {
          padding-left: 80px;
        }
        .details-multimedia-btn {
          left: calc(50% + 30px);
        }
      }
    }
    @include min-full-desktop {
      .gallery-details-multimedia {
        .wrap-details-multimedia {
          padding-left: 150px;
        }
        .details-multimedia-btn {
          left: calc(50% + 75px);
        }
      }
    }
  }
  .wrap-gallery-details {
    padding: 20px 35px;
  }
  @include min-medium-tablet {
    .wrap-gallery-details {
      padding: 30px 50px;
    }
  }
  @include min-full-tablet {
    @include small-desktop {
      .wrap-gallery-details {
        max-width: 765px;
        margin: 0 auto;
      }
    }
  }
  @include min-small-desktop {
    .wrap-gallery-details {
      padding: 0;
      position: relative;
    }
  }
}
.gallery-details-content {
  .wrap-details-content {
    padding-top: 20px;
  }
  .details-content-intro {
    padding-bottom: 35px;
    border-bottom: 1px solid $color-lines;
  }
  .details-content-title {
    font: 500 42px/1 $corp-font-titles;
    color: $color-titles;
    padding-bottom: 10px;
  }
  .details-content-subtitle {
    position: relative;
    font: 500 16px/1 $corp-font1;
    color: $color-corp2;
    @include space(50em);
    text-transform: uppercase;
    padding-bottom: 10px;
    &:before {
      content: "\e90f";
      position: absolute;
      right: calc(100% + 5px);
      top: 3px;
      font-family: "icomoon";
      font-size: 7px;
      color: $color-corp2;
    }
  }
  .details-content-text {
    font: 400 19px/1 $corp-font1;
    color: $color-text;
    @include space(20em);
    @include line(26, 19);
    white-space: pre-line;
  }
  .details-content-btn {
    margin-top: 15px;
  }
  .details-content-point {
    padding: 30px 0 30px 15px;
    border-bottom: 1px solid $color-lines;
  }
  @include min-medium-tablet {
    .wrap-details-content {
      padding: 35px 30px 0;
    }
    .details-content-intro {
      padding-bottom: 50px;
    }
    .details-content-title {
      font-size: 60px;
      padding-bottom: 20px;
    }
    .details-content-subtitle {
      font-size: 18px;
      padding-bottom: 15px;
    }
    .details-content-text {
      font-size: 20px;
      @include space(35em);
    }
    .details-content-point {
      padding: 40px 0 40px 15px;
    }
  }
  @include min-small-desktop {
    width: 50%;
    .wrap-details-content {
      padding: 80px 80px 60px;
    }
  }
  @include min-full-desktop {
    width: 50%;
    .wrap-details-content {
      padding: 90px 150px 80px;
    }
  }
}
.gallery-details-multimedia {
  .details-multimedia-box {
    position: relative;
    overflow: hidden;
    padding-top: 66%;
    background-color: $color-background;
  }
  .details-multimedia-graphic {
    position: absolute;
    top: 50%;
    left: 50%;
    @include transform(translateY(-50%) translateX(-50%));
    height: 75%;
    width: auto;
  }
  .details-multimedia-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .details-multimedia-btn {
    display: table;
    margin: 12px auto 0;
  }
  @include min-medium-tablet {
    .wrap-details-multimedia {
      position: relative;
    }
    .details-multimedia-btn {
      position: absolute;
      bottom: 0;
      left: 50%;
      margin: 0;
      @include transform(translateY(50%) translateX(-50%));
    }
  }
  @include min-small-desktop {
    position: absolute;
    top: 0;
    width: 50%;
    &.fixed {
      position: fixed;
      top: 130px;
      &.stop {
        position: absolute;
        top: auto;
        bottom: 0;
      }
    }
    .wrap-details-multimedia {
      padding: 60px 0;
      height: calc(100vh - 130px);
    }
    .details-multimedia-box {
      padding: 0;
      width: 100%;
      height: 100%;
    }
    .details-multimedia-graphic {
      max-width: 410px;
    }
    .details-multimedia-btn {
      bottom: 60px;
      left: calc(50% - 40px);
    }
  }
  @include min-full-desktop {
    .wrap-details-multimedia {
      padding: 80px 0;
    }
    .details-multimedia-btn {
      bottom: 80px;
    }
  }
}

.graphics-module {
  .wrap-graphics-module {
    padding: 20px 35px;
  }
  @include min-medium-tablet {
    .wrap-graphics-module {
      padding: 30px 80px;
    }
  }
  @include min-small-desktop {
    .wrap-graphics-module {
      padding: 45px 80px;
      font-size: 0;
    }
  }
}
.graphics-element-info {
  .graphics-info-title {
    font: 500 38px/1 $corp-font-titles;
    color: $color-titles;
    @include line(42, 38);
    padding-bottom: 10px;
  }
  .graphics-info-text {
    font: 400 18px/1 $corp-font1;
    color: $color-text;
    @include space(20em);
    @include line(26, 18);
  }
  @include min-medium-tablet {
    .wrap-graphics-info {
      padding-bottom: 10px;
    }
    .graphics-info-title {
      font-size: 45px;
      padding-bottom: 20px;
    }
    .graphics-info-text {
      max-width: 650px;
    }
  }
  @include min-small-desktop {
    display: inline-block;
    vertical-align: top;
    width: 300px;
  }
}
.graphics-element-list {
  @include min-medium-tablet {
    .wrap-graphics-list {
      font-size: 0;
      margin: 0 -35px;
    }
  }
  @include min-small-desktop {
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 300px);
    padding-left: 60px;
    margin-top: -20px;
    @include full-desktop {
      .wrap-graphics-list {
        margin: 0 -20px;
      }
    }
  }
  @include min-full-desktop {
    margin-top: -30px;
    padding-left: 80px;
  }
}
.graphics-list-element {
  .wrap-list-element {
    padding: 35px 0;
    text-align: center;
    border-bottom: 1px solid $color-lines;
  }
  .graphics-list-img {
    display: inline-block;
    width: 195px;
    height: auto;
    max-height: 70.05px;
  }
  @include min-medium-tablet {
    display: inline-block;
    vertical-align: top;
    width: 50%;
    padding: 0 35px;
  }
  @include min-full-tablet {
    width: 33.33%;
  }
  @include min-small-desktop {
    .wrap-list-element {
      @include hovers {
        .graphics-list-img {
          filter: grayscale(0%);
          -webkit-filter: grayscale(0%);
          -moz-filter: grayscale(0%);
          -ms-filter: grayscale(0%);
          -o-filter: grayscale(0%);
        }
      }
    }
    .graphics-list-img {
      filter: grayscale(100%);
      -webkit-filter: grayscale(100%);
      -moz-filter: grayscale(100%);
      -ms-filter: grayscale(100%);
      -o-filter: grayscale(100%);
      @include transition($Stransition);
    }
    @include full-desktop {
      padding: 0 20px;
    }
  }
  @include min-full-desktop {
    .wrap-list-element {
      padding: 40px 0;
    }
    .graphics-list-img {
      width: 240px;
    }
  }
}

.prices-module {
  .wrap-prices-module {
    padding: 30px 0;
  }
  .prices-module-title {
    font: 500 34px/1 $corp-font-titles;
    color: $color-titles;
    @include space(10em);
    @include line(40, 34);
    padding-top: 30px;
    text-align: center;
  }
  @include min-medium-tablet {
    .wrap-prices-module {
      padding: 40px 0;
      max-width: 450px;
      margin: 0 auto;
    }
    .prices-module-title {
      font-size: 45px;
    }
  }
  @include min-full-tablet {
    .big-wrapper {
      padding: 0 80px;
    }
    .wrap-prices-module {
      max-width: initial;
    }
  }
  @include min-full-desktop {
    .big-wrapper {
      padding: 0 150px;
    }
    .wrap-prices-module {
      max-width: 1460px;
      margin: 0 auto;
    }
  }
}
.prices-module-filter {
  padding-bottom: 30px;
  .wrap-prices-filter {
    padding: 20px;
    border-radius: 4px;
    border: 1px solid $color-lines;
  }
  @include min-medium-tablet {
    .wrap-prices-filter {
      padding: 20px 30px;
    }
  }
  @include min-full-tablet {
    padding-bottom: 30px;
    .wrap-prices-filter {
      padding: 30px 90px;
      font-size: 0;
    }
  }
  @include min-small-desktop {
    width: 850px;
    margin: 0 auto;
  }
}
.prices-filter-header {
  padding-bottom: 12px;
  .prices-filter-title {
    font: 500 34px/1 $corp-font-titles;
    color: $color-titles;
    @include space(10em);
    @include line(40, 34);
    padding-bottom: 5px;
  }
  .prices-filter-subtitle {
    font: 400 16px/1 $corp-font1;
    color: $color-text;
    @include space(10em);
  }
  @include min-medium-tablet {
    .prices-filter-title {
      font-size: 45px;
    }
    .prices-filter-subtitle {
      font-size: 18px;
    }
  }
  @include min-full-tablet {
    padding: 0 40px 0 0;
    display: inline-block;
    vertical-align: middle;
    width: calc(100% - 300px);
  }
}
.prices-filter-form {
  .prices-filter-field {
    position: relative;
    border-radius: 2px;
    border: 2px solid $color-corp1;
    height: 50px;
    &:after {
      content: "\e90c";
      position: absolute;
      top: calc(50% - 5px);
      right: 15px;
      font-family: "icomoon";
      font-size: 10px;
      color: $color-corp1;
      pointer-events: none;
    }
  }
  .filter-field-select {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font: 500 18px/1 $corp-font1;
    color: $color-corp1;
    @include space(30em);
    padding: 10px 40px 10px 15px;
    option {
      font: 500 18px/1 $corp-font1;
      color: $color-corp1;
      @include space(30em);
    }
  }
  @include min-medium-tablet {
    .prices-filter-field {
      height: 60px;
    }
  }
  @include min-full-tablet {
    padding-top: 8px;
    display: inline-block;
    vertical-align: middle;
    width: 300px;
  }
}
.prices-module-list {
  .wrap-prices-list {
    padding-bottom: 15px;
    text-align: center;
  }
  @include min-full-tablet {
    margin: 0 -7px;
    .wrap-prices-list {
      font-size: 0;
      padding-bottom: 80px;
    }
  }
  @include min-full-desktop {
    margin: 0 -15px;
    .wrap-prices-list {
      padding-bottom: 100px;
    }
  }
}
.empty-message {
  display: none;
  padding-bottom: 30px;
  max-width: 850px;
  margin: 0 auto;
  &.show {
    display: block;
  }
  .wrap-empty-message {
    position: relative;
    border-radius: 4px;
    border: 1px solid $color-lines;
    text-align: center;
    padding: 20px;
    background-color: $color-background;
  }
  .empty-message-text {
    font: 500 22px/1 $corp-font-titles;
    color: $color-corp1-hover;
    @include space(10em);
    @include line(25, 22);
  }
  @include min-medium-tablet {
    .wrap-empty-message {
      padding: 30px 20px;
    }
    .empty-message-text {
      font-size: 26px;
    }
  }
}
.prices-list-element {
  padding-bottom: 60px;
  .wrap-prices-element {
    position: relative;
    border-radius: 4px;
    border: 1px solid $color-lines;
    text-align: center;
  }
  .prices-element-tag {
    position: absolute;
    z-index: 1;
    left: 50%;
    bottom: 100%;
    @include transform(translateY(50%) translateX(-50%));
    font: 700 12px/1 $corp-font1;
    color: #fff;
    @include space(100em);
    text-align: center;
    padding: 6px 0;
    background-color: $color-corp2;
    text-transform: uppercase;
    width: 130px;
  }
  @include min-full-tablet {
    display: inline-block;
    vertical-align: top;
    width: 50%;
    padding: 7px 7px 60px;
    @include full-desktop {
      .wrap-prices-element {
        min-height: 324px;
      }
    }
  }
  @include min-small-desktop {
    width: 25%;
    padding: 0 7px;
  }
  @include min-full-desktop {
    padding: 0 15px;
    .wrap-prices-element {
      min-height: 447px;
    }
  }
}
.prices-element-header {
  position: relative;
  padding: 35px 20px 15px;
  background-color: $color-background;
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 20px;
    width: calc(100% - 40px);
    height: 1px;
    background-color: $color-lines;
  }
  .prices-element-title {
    font: 500 48px/1 $corp-font-titles;
    color: $color-corp1-hover;
    @include space(10em);
    padding-bottom: 5px;
  }
  .prices-element-subtitle {
    font: 500 16px/1 $corp-font1;
    color: $color-corp2;
    @include space(30em);
  }
  @include min-medium-tablet {
    @include full-tablet {
      padding: 60px 30px 20px;
      &:after {
        left: 30px;
        width: calc(100% - 60px);
      }
      .prices-element-title {
        font-size: 60px;
      }
      .prices-element-subtitle {
        font-size: 23px;
      }
    }
  }
  @include min-full-tablet {
    @include full-desktop {
      .prices-element-title {
        font-size: 46px;
      }
    }
  }
  @include min-full-desktop {
    padding: 60px 30px 20px;
    &:after {
      left: 30px;
      width: calc(100% - 60px);
    }
    .prices-element-title {
      font-size: 60px;
    }
    .prices-element-subtitle {
      font-size: 23px;
    }
  }
}
.prices-element-info {
  position: relative;
  padding: 25px 20px;
  font-size: 0;
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 20px;
    width: calc(100% - 40px);
    height: 1px;
    background-color: $color-lines;
  }
  .prices-info-element {
    display: inline-block;
    vertical-align: middle;
    width: 50%;
  }
  .prices-info-number {
    display: block;
    font: 500 54px/1 $corp-font-titles;
    color: $color-corp1;
    @include space(10em);
  }
  .prices-info-text {
    display: block;
    font: 400 16px/1 $corp-font1;
    color: $color-text;
    @include space(10em);
    padding-top: 5px;
  }
  .prices-info-phrase {
    font: 500 30px/1 $corp-font-titles;
    color: $color-corp1;
    @include space(10em);
    @include line(33, 30);
  }
  @include min-medium-tablet {
    @include full-tablet {
      padding: 40px 30px;
      &:after {
        left: 30px;
        width: calc(100% - 60px);
      }
      .prices-info-number {
        font-size: 80px;
      }
      .prices-info-text {
        font-size: 20px;
      }
      .prices-info-phrase {
        font-size: 42px;
      }
    }
  }
  @include min-full-tablet {
    @include full-desktop {
      min-height: 141px;
      .prices-info-phrase {
        font-size: 25px;
        line-height: 1;
        max-width: 260px;
        margin: 0 auto;
      }
    }
  }
  @include min-full-desktop {
    padding: 40px 30px;
    min-height: 205px;
    &:after {
      left: 30px;
      width: calc(100% - 60px);
    }
    .prices-info-number {
      font-size: 80px;
    }
    .prices-info-text {
      font-size: 20px;
    }
    .prices-info-phrase {
      font-size: 42px;
    }
  }
}
.prices-element-foot {
  position: relative;
  padding: 15px 20px 45px;
  .prices-foot-text {
    font: 400 16px/1 $corp-font1;
    color: $color-text;
    min-height: 25px;
    @include space(10em);
    @include line(26, 16);
  }
  @include min-medium-tablet {
    @include full-tablet {
      padding: 20px 50px 45px;
      .prices-foot-text {
        font-size: 20px;
      }
    }
  }
  @include min-full-tablet {
    @include full-desktop {
      padding: 15px 20px 45px;
      min-height: 80px;
    }
  }
  @include min-full-desktop {
    padding: 20px 50px 60px;
    min-height: 124px;
    .prices-foot-text {
      font-size: 20px;
    }
  }
}
.prices-foot-rate {
  padding-top: 20px;
  .prices-foot-rate_text {
    padding-top: 15px;
    font: 400 16px/1 $corp-font1;
    color: $color-text;
    @include space(10em);
    @include line(26, 16);
    border-top: 1px solid $color-lines;
  }
  .prices-foot-rate_text-price {
    padding-left: 10px;
    font: 400 30px/1 $corp-font-titles;
    color: $color-corp1;
    @include space(10em);
    vertical-align: middle;
  }
  @include min-full-desktop {
    .prices-foot-rate_text {
      font-size: 20px;
    }
    .prices-foot-rate_text-price {
      font-size: 34px;
      padding-left: 15px;
    }
  }
}
.prices-foot-btn {
  position: absolute;
  bottom: -25px;
  left: 0;
  width: 100%;
  text-align: center;
  .btn-corp {
    &:not(:hover) {
      .btn-corp-text {
        border-color: #b7afaf;
        color: #b7afaf;
      }
    }
  }
}
.prices-module-extra {
  .wrap-prices-extra {
    position: relative;
    padding: 35px 20px 20px;
    border-radius: 4px;
    border: 1px solid $color-lines;
  }
  .prices-extra-title {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 10px;
    @include transform(translateY(-50%));
    background-color: #fff;
    padding: 0 10px;
    font: 500 40px/1 $corp-font-titles;
    color: $color-corp2;
    @include space(10em);
  }
  @include min-medium-tablet {
    .wrap-prices-extra {
      padding: 35px 30px 20px;
    }
    .prices-extra-title {
      left: 20px;
    }
  }
  @include min-full-desktop {
    .wrap-prices-extra {
      padding: 35px 70px 20px;
    }
    .prices-extra-title {
      left: 60px;
    }
  }
}
.prices-extra-list {
  @include min-full-tablet {
    font-size: 0;
    margin: 0 -25px;
  }
}
.prices-extra-element {
  &:last-child {
    @include full-tablet {
      .wrap-extra-element {
        border-bottom: none;
      }
    }
  }

  .wrap-extra-element {
    padding: 15px 0 15px 15px;
    border-bottom: 1px solid $color-lines;
  }
  .extra-element-text {
    position: relative;
    font: 400 14px/1 $corp-font1;
    color: $color-text;
    @include space(50em);
    @include line(20, 14);
    &:before {
      content: "\e90f";
      position: absolute;
      top: 0px;
      right: calc(100% + 5px);
      font-family: "icomoon";
      font-size: 7px;
      color: $color-text;
    }
  }
  @include min-medium-tablet {
    .wrap-extra-element {
      padding: 20px 0 20px 15px;
    }
    .extra-element-text {
      font-size: 16px;
    }
  }
  @include min-full-tablet {
    display: inline-block;
    vertical-align: top;
    width: 50%;
    padding: 0 25px;
    &:nth-child(1),
    &:nth-child(2) {
      .wrap-extra-element {
        border-top: none;
      }
    }
    .wrap-extra-element {
      border-top: 1px solid $color-lines;
      border-bottom: none;
    }
  }
  @include min-full-desktop {
    width: 33.33%;
    &:nth-child(3) {
      .wrap-extra-element {
        border-top: none;
      }
    }
  }
}

.contact-page {
  @include min-medium-tablet {
    background-color: #f8f6f2;
    .wrap-contact-page {
      padding-bottom: 60px;
    }
  }
  @include mobile {
    background-color: #f8f6f2;
  }
}
.contact-page-header {
  .wrap-contact-header {
    padding-top: 30px;
    .contact-header-title {
      font-family: $corp-font-titles;
      font-size: 38px;
      font-weight: 500;
      color: #383838;
    }
  }
  @include min-medium-tablet {
    .wrap-contact-header {
      position: relative;
      padding: 50px 0 0;
      text-align: left;
      .contact-header-title {
        font-size: 45px;
      }
      .contact-header-btn {
        display: block;
        position: absolute;
        top: 50%;
        right: 0;
        @include transform(translateY(-50%));
      }
    }
  }
  @include min-full-tablet {
    .wrap-contact-header {
      padding: 0;
    }
  }
}
.contact-page-info {
  &.pre-table {
    .wrap-contact-info {
      padding-bottom: 0;
      .contact-info-title {
        padding-bottom: 0;
      }
    }
  }
  .wrap-contact-info {
    padding: 10px 0 20px;
    .contact-info-title {
      font-family: $corp-font1;
      font-size: 16px;
      font-weight: 700;
      color: $color-corp2;
      @include space(50em);
      @include line(20, 16);
      text-transform: uppercase;
      padding: 20px 0 10px;
    }
    .contact-info-text {
      font-family: $corp-font1;
      font-size: 18px;
      font-weight: 400;
      color: $color-text;
      @include space(20em);
      @include line(32, 20);
      white-space: pre-line;
      &.subtitle {
        font-weight: 700;
        padding: 30px 0 15px;
      }
      a {
        color: $color-text;
        font-weight: 700;
      }
    }
  }
  @include min-medium-tablet {
    .wrap-contact-info {
      padding: 20px 0 0px;
      .contact-info-title {
        font-size: 18px;
        padding-bottom: 20px;
      }
      .contact-info-text {
        font-size: 20px;
      }
    }
  }
  @include min-small-desktop {
    @include full-desktop {
      .wrap-contact-info {
        .contact-info-text {
          font-size: 18px;
        }
      }
    }
  }
}
.contact-page-form {
  .wrap-contact-form {
    padding: 10px 0 35px;
    .contact-form-title {
      font-family: $corp-font1;
      font-size: 16px;
      font-weight: 700;
      color: $color-titles;
      text-transform: uppercase;
      @include space(150em);
    }
  }
  @include min-medium-tablet {
    .wrap-contact-form {
      padding: 0;
    }
  }
}
.contact-form-fields {
  .wrap-contact-fields {
    padding: 10px 0 0 20px;
  }
  @include min-medium-tablet {
    .wrap-contact-fields {
      padding: 20px 0 0 20px;
      font-size: 0;
      margin: 0 -25px;
    }
  }
}
.contact-fields-element {
  display: inline-block;
  padding-bottom: 25px;
  @include min-medium-tablet {
    vertical-align: top;
    width: 50%;
    padding: 0 25px 40px;
    &.full {
      width: 100%;
    }
  }
}

.contact-fields-element {
  display: block;
  padding-bottom: 25px;
  @include min-medium-tablet {
    vertical-align: top;
    width: 50%;
    padding: 0 25px 40px;
    &.full {
      width: 100%;
    }
  }
}

.members-fields-element {
  display: inline-block;
  padding-bottom: 25px;
  @include min-medium-tablet {
    vertical-align: top;
    width: 50%;
    padding: 0 25px 40px;
    &.full {
      width: 100%;
    }
  }
  @include mobile {
    display: block;
  }
}

.contact-form-foot {
  .wrap-form-foot {
    padding: 0 0 0 20px;
  }
  @include min-medium-tablet {
    .wrap-form-foot {
      font-size: 0;
    }
  }
}

.form-foot-checks {
  .wrap-foot-checks {
    padding-bottom: 10px;
    .must-element {
      padding-bottom: 10px;
    }
    .checkbox-element {
      padding-bottom: 10px;
    }
  }
  @include min-medium-tablet {
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 240px);
    .wrap-foot-checks {
      padding-bottom: 0;
    }
  }
}
.must-element {
  .must-element-text {
    position: relative;
    font-family: $corp-font1;
    font-size: 15px;
    font-weight: 400;
    color: $color-text;
    @include space(40em);
    @include line(25, 15);
    padding-left: 25px;
    &:before {
      content: "";
      position: absolute;
      top: 8px;
      left: 6px;
      width: 6px;
      height: 6px;
      background-color: $color-corp2;
      border-radius: 50%;
    }
  }
}
.form-foot-btns {
  .wrap-foot-btns {
    text-align: center;
  }
  @include min-medium-tablet {
    display: inline-block;
    vertical-align: top;
  }
}
.contact-page-details {
  .wrap-contact-details {
    padding: 30px 0;
  }
}
.contact-details-element {
  .wrap-details-element {
    padding: 35px 0;
    border-bottom: 1px solid $color-lines;
  }
  &:first-child {
    .wrap-details-element {
      border-top: 1px solid $color-lines;
    }
  }
  @include min-medium-tablet {
    .wrap-details-element {
      font-size: 0;
      padding: 40px 0;
    }
  }
}
.details-element-header {
  .wrap-details-header {
    padding-bottom: 10px;
    .details-header-title {
      font-family: $corp-font1;
      font-size: 16px;
      font-weight: 700;
      color: $color-text;
      text-transform: uppercase;
      @include space(50em);
    }
  }
  @include min-medium-tablet {
    display: inline-block;
    vertical-align: top;
    width: 275px;
    .wrap-details-header {
      padding-bottom: 0;
      padding-top: 7px;
    }
  }
}
.details-element-content {
  .wrap-details-content {
    .details-content-text {
      display: table;
      font-family: $corp-font1;
      font-size: 18px;
      font-weight: 400;
      color: $color-text;
      @include line(30, 18);
      @include space(20em);
      @include transition($Stransition);
      small {
        font-weight: 500;
        display: inline-block;
        vertical-align: baseline;
        padding-left: 5px;
      }
      &.link {
        position: relative;
        &:before {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          width: 0%;
          height: 1px;
          background-color: $color-corp1;
          @include transition($Stransition);
        }
        @include hovers {
          color: $color-corp1;
          &:before {
            width: 100%;
          }
        }
      }
    }
  }
  @include min-medium-tablet {
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 275px);
  }
}

.popup-module {
  display: none;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  pointer-events: none;
  @include transition($Stransition);
  transition-delay: 0.4s;
  -webkit-transition-delay: 0.4s;
  -moz-transition-delay: 0.4s;
  -ms-transition-delay: 0.4s;
  -o-transition-delay: 0.4s;
  &.full {
    &:before {
      background-color: #fff;
      pointer-events: none;
    }
    .wrap-popup-module {
      .popup-module-backmask {
        pointer-events: none;
      }
    }
    .popup-module-box {
      max-width: initial;
      .wrap-popup-box {
        max-width: initial;
      }
    }
    @include min-medium-tablet {
      &.open {
        .popup-module-box {
          .popup-box-close {
            @include transform(translateX(0) scale(1));
          }
        }
      }
      .popup-module-box {
        .popup-box-close {
          right: 20px;
          border-color: $color-corp1;
          &:before {
            color: $color-corp1;
          }
          @include hovers {
            background-color: $color-corp1;
            &:before {
              color: #fff;
            }
          }
        }
      }
    }
    @include min-full-tablet {
      .popup-module-box {
        .wrap-popup-box {
          max-width: 820px;
          margin: 0 auto;
        }
        .popup-box-close {
          top: 20px;
          right: calc(50% - 410px);
        }
      }
    }
    @include min-full-desktop {
      .popup-module-box {
        .wrap-popup-box {
          max-width: 1060px;
        }
        .popup-box-close {
          top: 20px;
          right: calc(50% - 530px);
        }
      }
    }
  }
  .popup-box-video {
    position: relative;
    @include responsive-ratio(16, 9);
    .popup-video-element {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  &.open {
    @include medium-tablet {
      pointer-events: auto;
      opacity: 1;
      transition-delay: 0s;
      -webkit-transition-delay: 0s;
      -moz-transition-delay: 0s;
      -ms-transition-delay: 0s;
      -o-transition-delay: 0s;
      &:before {
        @include transform(translateY(0%));
        transition-delay: 0s;
        -webkit-transition-delay: 0s;
        -moz-transition-delay: 0s;
        -ms-transition-delay: 0s;
        -o-transition-delay: 0s;
      }
      .popup-module-box {
        .popup-box-close {
          opacity: 1;
          @include transform(translateY(0px));
          transition-delay: 0.4s;
          -webkit-transition-delay: 0.4s;
          -moz-transition-delay: 0.4s;
          -ms-transition-delay: 0.4s;
          -o-transition-delay: 0.4s;
        }
        .wrap-popup-box {
          opacity: 1;
          @include transform(translateY(0px));
          transition-delay: 0.4s;
          -webkit-transition-delay: 0.4s;
          -moz-transition-delay: 0.4s;
          -ms-transition-delay: 0.4s;
          -o-transition-delay: 0.4s;
        }
      }
    }
    @include min-medium-tablet {
      pointer-events: auto;
      opacity: 1;
      transition-delay: 0s;
      -webkit-transition-delay: 0s;
      -moz-transition-delay: 0s;
      -ms-transition-delay: 0s;
      -o-transition-delay: 0s;
      &:before {
        @include transform(translateY(0%));
        transition-delay: 0s;
        -webkit-transition-delay: 0s;
        -moz-transition-delay: 0s;
        -ms-transition-delay: 0s;
        -o-transition-delay: 0s;
      }
      .popup-module-box {
        opacity: 1;
        @include transform(translateY(0px));
        transition-delay: 0.4s;
        -webkit-transition-delay: 0.4s;
        -moz-transition-delay: 0.4s;
        -ms-transition-delay: 0.4s;
        -o-transition-delay: 0.4s;
        .popup-box-close {
          opacity: 1;
          @include transform(translateY(-100%) translateX(100%) scale(1));
          transition-delay: 0.4s;
          -webkit-transition-delay: 0.4s;
          -moz-transition-delay: 0.4s;
          -ms-transition-delay: 0.4s;
          -o-transition-delay: 0.4s;
        }
      }
    }
  }
  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    @include transform(translateY(100%));
    @include transition($Stransition);
    transition-delay: 0.4s;
    -webkit-transition-delay: 0.4s;
    -moz-transition-delay: 0.4s;
    -ms-transition-delay: 0.4s;
    -o-transition-delay: 0.4s;
  }
  .wrap-popup-module {
    position: relative;
    height: 100%;
    font-size: 0;
    text-align: center;
    &:before {
      content: "";
      position: relative;
      display: inline-block;
      vertical-align: middle;
      width: 0;
      height: 100%;
      opacity: 0;
      pointer-events: none;
    }
    .popup-module-backmask {
      display: none;
    }
  }
  @include min-medium-tablet {
    &:before {
      background-color: rgba(#000, 0.7);
    }
    .wrap-popup-module {
      .popup-module-backmask {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        cursor: zoom-out;
      }
    }
  }
}
.popup-module-box {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  .popup-box-close {
    position: absolute;
    z-index: 2;
    top: 20px;
    right: 20px;
    opacity: 0;
    @include transform(translateY(-30px));
    @include transition($Stransition);
    @include hovers {
      background-color: #fff;
      transition-delay: 0s;
      -webkit-transition-delay: 0s;
      -moz-transition-delay: 0s;
      -ms-transition-delay: 0s;
      -o-transition-delay: 0s;
    }
  }
  .wrap-popup-box {
    opacity: 0;
    @include transform(translateY(30px));
    @include transition($Stransition);
    padding: 60px 35px;
    max-width: 370px;
    margin: 0 auto;
    max-height: 100vh;
    overflow: auto;
    &.align-left {
      text-align: left;
    }
    .popup-box-icon {
      display: inline-block;
      padding-bottom: 15px;
      font-size: 28px;
      color: $color-text;
      &.icon-guests {
        font-size: 30px;
      }
    }
    .popup-box-title {
      font-family: $corp-font1;
      font-size: 19px;
      font-weight: 400;
      color: #383838;
      @include space(60em);
      padding-bottom: 10px;
    }
    .popup-box-subtitle {
      font-family: $corp-font-titles;
      font-size: 30px;
      font-weight: 500;
      color: #383838;
      @include space(20em);
      padding-bottom: 50px;
      &:after {
        content: "";
        display: block;
        margin: 25px auto 0;
        width: 50px;
        height: 1px;
        background-color: $color-corp2;
      }
    }
    .popup-box-text {
      font-family: $corp-font1;
      font-size: 17px;
      font-weight: 400;
      color: $color-text;
      @include space(15em);
      @include line(23, 18);
    }
  }
  @include min-medium-tablet {
    position: relative;
    z-index: 2;
    max-width: 400px;
    background-color: #fff;
    border-radius: 4px;
    opacity: 0;
    @include transform(translateY(30px));
    @include transition($Stransition);
    &.bigger {
      max-width: 564px;
    }
    .popup-box-close {
      top: -10px;
      right: -20px;
      @include transform(translateY(-100%) translateX(100%) scale(0));
      border-color: #fff;
      transition-delay: 0s;
      -webkit-transition-delay: 0s;
      -moz-transition-delay: 0s;
      -ms-transition-delay: 0s;
      -o-transition-delay: 0s;
      &:before {
        color: #fff;
      }
      &:after {
        background-color: #fff;
      }
      @include hovers {
        &:before {
          color: $color-corp1;
        }
      }
    }
    .wrap-popup-box {
      opacity: 1;
      @include transform(none);
      padding: 50px;
      max-width: initial;
      max-height: 85vh;
      .popup-box-title {
        padding-bottom: 20px;
      }
      .popup-box-subtitle {
        font-size: 34px;
        padding-bottom: 30px;
      }
    }
  }
  @include min-full-tablet {
    &.bigger {
      max-width: 895px;
    }
  }
}
.popup-box-languages {
  .btn-languages {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
    &.active {
      .btn-corp-text {
        background-color: $color-corp1;
        color: #fff;
        border-color: transparent;
      }
    }
    .btn-corp-text {
      min-width: 200px;
    }
  }
  @include min-medium-tablet {
    .btn-languages {
      margin: 0 auto 25px;
      .btn-corp-text {
        min-width: 260px;
      }
    }
  }
}
.popup-box-newsletter {
  .wrap-box-newsletter {
    padding-top: 20px;
    border-top: 1px solid $color-lines;
    .popup-newsletter-input {
      padding-top: 25px;
    }
    .popup-newsletter-checkbox {
      padding-top: 20px;
    }
  }
}
.popup-box-list {
  padding-top: 20px;
}
.popup-list-element {
  .wrap-list-element {
    position: relative;
    padding: 23px 20px 21px;
    text-align: left;
    border-top: 1px solid $color-lines;
    &:before {
      content: "\e903";
      position: absolute;
      left: 0;
      top: 28px;
      font-family: "icomoon";
      font-size: 7px;
      color: $color-text;
    }
    .list-element-text {
      font-family: $corp-font1;
      font-size: 18px;
      font-weight: 400;
      color: $color-text;
      @include space(35em);
    }
  }
}

.popup-notification {
  display: none;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  pointer-events: none;
  @include transition($Stransition);
  transition-delay: 0.4s;
  -webkit-transition-delay: 0.4s;
  -moz-transition-delay: 0.4s;
  -ms-transition-delay: 0.4s;
  -o-transition-delay: 0.4s;
  &.open {
    @include medium-tablet {
      pointer-events: auto;
      opacity: 1;
      transition-delay: 0s;
      -webkit-transition-delay: 0s;
      -moz-transition-delay: 0s;
      -ms-transition-delay: 0s;
      -o-transition-delay: 0s;
      &:before {
        @include transform(translateY(0%));
        transition-delay: 0s;
        -webkit-transition-delay: 0s;
        -moz-transition-delay: 0s;
        -ms-transition-delay: 0s;
        -o-transition-delay: 0s;
      }
      .popup-notification-box {
        .popup-notification-close {
          opacity: 1;
          @include transform(translateY(0px));
          transition-delay: 0.4s;
          -webkit-transition-delay: 0.4s;
          -moz-transition-delay: 0.4s;
          -ms-transition-delay: 0.4s;
          -o-transition-delay: 0.4s;
        }
        .wrap-notification-box {
          opacity: 1;
          @include transform(translateY(0px));
          transition-delay: 0.4s;
          -webkit-transition-delay: 0.4s;
          -moz-transition-delay: 0.4s;
          -ms-transition-delay: 0.4s;
          -o-transition-delay: 0.4s;
        }
      }
    }
    @include min-medium-tablet {
      pointer-events: auto;
      opacity: 1;
      transition-delay: 0s;
      -webkit-transition-delay: 0s;
      -moz-transition-delay: 0s;
      -ms-transition-delay: 0s;
      -o-transition-delay: 0s;
      &:before {
        @include transform(translateY(0%));
        transition-delay: 0s;
        -webkit-transition-delay: 0s;
        -moz-transition-delay: 0s;
        -ms-transition-delay: 0s;
        -o-transition-delay: 0s;
      }
      .popup-notification-box {
        opacity: 1;
        @include transform(translateY(0px));
        transition-delay: 0.4s;
        -webkit-transition-delay: 0.4s;
        -moz-transition-delay: 0.4s;
        -ms-transition-delay: 0.4s;
        -o-transition-delay: 0.4s;
        .popup-notification-close {
          opacity: 1;
          @include transform(translateY(0%) translateX(100%) scale(1));
          transition-delay: 0.4s;
          -webkit-transition-delay: 0.4s;
          -moz-transition-delay: 0.4s;
          -ms-transition-delay: 0.4s;
          -o-transition-delay: 0.4s;
          &:after {
            background-color: #fff;
          }
          @include hovers {
            &:before {
              color: $color-corp1;
            }
          }
        }
        .wrap-notification-box {
          opacity: 1;
          @include transform(translateY(0px));
          transition-delay: 0.4s;
          -webkit-transition-delay: 0.4s;
          -moz-transition-delay: 0.4s;
          -ms-transition-delay: 0.4s;
          -o-transition-delay: 0.4s;
        }
      }
    }
  }
  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(#000, 0.7);
    @include transform(translateY(100%));
    @include transition($Stransition);
    transition-delay: 0.4s;
    -webkit-transition-delay: 0.4s;
    -moz-transition-delay: 0.4s;
    -ms-transition-delay: 0.4s;
    -o-transition-delay: 0.4s;
  }
  .wrap-popup-notification {
    position: relative;
    height: 100%;
    font-size: 0;
    text-align: center;
    &:before {
      content: "";
      display: inline-block;
      vertical-align: middle;
      width: 0;
      height: 100%;
      opacity: 0;
      pointer-events: none;
    }
    .popup-notification-backmask {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      cursor: zoom-out;
    }
  }
}
.popup-notification-box {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  padding: 0 25px;
  .popup-notification-close {
    position: absolute;
    z-index: 2;
    bottom: calc(100% + 20px);
    right: 25px;
    opacity: 0;
    @include transform(translateY(-30px));
    @include transition($Stransition);
    border-color: #fff;
    &:before {
      color: #fff;
    }
    @include hovers {
      background-color: #fff;
      transition-delay: 0s;
      -webkit-transition-delay: 0s;
      -moz-transition-delay: 0s;
      -ms-transition-delay: 0s;
      -o-transition-delay: 0s;
    }
  }
  .wrap-notification-box {
    opacity: 0;
    @include transform(translateY(30px));
    @include transition($Stransition);
    padding: 60px 25px 25px;
    margin: 0 auto;
    max-height: 80vh;
    overflow: auto;
    background-color: #fff;
    border-radius: 4px;
    .popup-notification-tag {
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba($color-corp1, 0.8);
      padding: 8px 30px 7px;
      font-family: $corp-font1;
      font-size: 15px;
      font-weight: 700;
      color: #fff;
      text-transform: uppercase;
      @include space(100em);
    }
  }
  @include min-small-tablet {
    padding: 0 40px;
    .popup-notification-close {
      right: 40px;
    }
  }
  @include min-medium-tablet {
    padding: 0;
    max-width: 530px;
    .popup-notification-close {
      right: 0;
    }
    .wrap-notification-box {
      padding: 60px 60px 40px;
    }
  }
}
.popup-notification-info {
  .wrap-notification-info {
    position: relative;
    padding-bottom: 30px;
    text-align: left;
    .notification-info-title {
      font-family: $corp-font-titles;
      font-size: 26px;
      font-weight: 500;
      color: $color-titles;
      @include space(15em);
      @include line(28, 26);
    }
    .notification-info-subtitle {
      font-family: $corp-font1;
      font-size: 12px;
      font-weight: 700;
      color: $color-subtitles;
      text-transform: uppercase;
      @include space(150em);
      @include line(20, 15);
      padding-bottom: 20px;
    }
    .notification-info-text {
      font-family: $corp-font1;
      font-size: 16px;
      font-weight: 400;
      color: $color-text;
      @include space(20em);
      @include line(24, 16);
      &:before {
        content: "";
        display: block;
        width: 50px;
        height: 1px;
        background-color: $color-corp2;
        margin: 25px 0;
      }
    }
  }
  @include min-medium-tablet {
    .wrap-notification-info {
      .notification-info-title {
        font-size: 32px;
      }
      .notification-info-date {
        font-size: 14px;
      }
      .notification-info-subtitle {
        font-size: 14px;
      }
      .notification-info-text {
        font-size: 18px;
      }
    }
  }
  @include min-small-desktop {
    width: 400px;
  }
}
.popup-notification-price {
  .wrap-notification-price {
    overflow: hidden;
    padding-bottom: 25px;
    .notification-price-since {
      float: left;
      font-family: $corp-font1;
      font-size: 15px;
      font-weight: 700;
      color: rgba($color-text, 0.8);
      text-transform: uppercase;
      @include space(50em);
    }
    .notification-price-number {
      float: right;
      font-size: 0;
      .price-number {
        display: inline-block;
        vertical-align: middle;
        font-family: $corp-font1;
        font-size: 50px;
        font-weight: 400;
        color: $color-text;
        line-height: 0.8;
      }
      .price-coin {
        display: inline-block;
        vertical-align: middle;
        font-family: $corp-font1;
        font-size: 35px;
        font-weight: 400;
        color: $color-text;
        padding-left: 5px;
      }
    }
  }
  @include min-medium-tablet {
    .wrap-notification-price {
      .notification-price-number {
        .price-number {
          font-size: 64px;
        }
        .price-coin {
          font-size: 44px;
        }
      }
    }
  }
}
.popup-notification-btns {
  text-align: center;
  .notification-btns-element {
    display: inline-block;
    vertical-align: middle;
    padding: 0 20px 15px;
    &:first-child {
      padding-left: 0;
    }
    &:nth-child(2) {
      padding-right: 0;
    }
  }
  @include min-medium-tablet {
    overflow: hidden;
    .notification-btns-element {
      .btn-corp {
        .btn-corp-text {
          min-width: 260px;
        }
      }
      .btn-linker {
        padding-top: 10px;
      }
      &:first-child {
        float: left;
      }
      &:nth-child(2) {
        float: right;
      }
    }
  }
  @include min-small-desktop {
    .notification-btns-element {
      .btn-corp {
        .btn-corp-text {
          min-width: 250px;
        }
      }
    }
  }
  @include small-tablet {
    .notification-btns-element {
      padding: 0 0 15px;
    }
  }
}
.popup-notification-img {
  display: none;
  @include min-small-desktop {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 50%;
    .wrap-notification-img {
      position: relative;
      height: 100%;
      width: 100%;
      overflow: hidden;
      .notification-img-photo {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 50%;
      }
    }
  }
}

.aside-pagenav {
  display: none;
}
@include min-full-tablet {
  .aside-pagenav {
    display: block;
    position: fixed;
    z-index: 8;
    top: 50%;
    right: 0;
    @include transform(translateY(-50%));
    @include transition($Stransition);
    &.stop {
      opacity: 0;
      pointer-events: none;
    }
    .wrap-aside-pagenav {
      padding-right: 28px;
    }
  }
  .aside-pagenav-list {
  }
  .aside-pagenav-element {
    padding: 7px;
    cursor: pointer;
    &.active {
      .pagenav-element-tag {
        opacity: 1;
        @include transform(translateY(-50%) translateX(0px) scale(1));
      }
      .pagenav-element-dot {
        background-color: $color-corp2;
        border-color: transparent;
        &:before {
          opacity: 0;
        }
      }
    }
    &:hover {
      .pagenav-element-tag {
        opacity: 1;
        @include transform(translateY(-50%) translateX(0px) scale(1));
      }
    }
    .wrap-pagenav-element {
      position: relative;
    }
    .pagenav-element-tag {
      position: absolute;
      top: 50%;
      right: 26px;
      @include transform(translateY(-50%) translateX(5px) scale(0.8));
      transform-origin: center right;
      padding: 9px 5px;
      border-radius: 2px;
      background-color: $color-corp2;
      font: 500 13px/1 $corp-font1;
      @include space(40em);
      text-align: center;
      color: #fff;
      width: 130px;
      opacity: 0;
      @include transition($Stransition);
      pointer-events: none;
      &:after {
        content: "\e90f";
        position: absolute;
        top: calc(50% - 4px);
        left: calc(100% - 1px);
        font-family: "icomoon";
        font-size: 8px;
        color: $color-corp2;
      }
    }
    .pagenav-element-dot {
      position: relative;
      display: block;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      border: 2px solid rgba(#00785a, 0.4);
      @include transition($Stransition);
      &:before {
        content: "";
        position: absolute;
        top: -2px;
        left: -2px;
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        border-radius: 50%;
        border: 2px solid rgba(#fff, 0.4);
        @include transition($Stransition);
      }
    }
  }
}

.form-message {
  position: relative;
  font-size: 15px;
  font-weight: 400;
  color: #444444;
  letter-spacing: 0.04em;
  line-height: 166.66667%;
  text-align: left;
}

.form-message-success {
  color: $color-subtitles;
}
.form-message-error {
  color: red;
}
