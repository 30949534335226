@import "variables";

.grid_container {
  display: grid;
  height: 100vh;
  background-color: #f8f6f2;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 0px 0px;
  grid-template-areas:
    "item1 item3 "
    "item2 item3 "
    ". item3 ";
}

.imgComingSoon {
  width: 50vw;
  height: 99.5vh;
  object-fit: cover;
}

.logoImg {
  width: 10vw;
  padding-top: 20px;
  padding-left: 30px;
}

.item1 {
  grid-area: item1;
}

.item2 {
  grid-area: item2;
  justify-self: center;
  align-self: end;
}

.item3 {
  grid-area: item3;
}

.textTitle {
  font-size: 55px;
  font-family: $corp-font-titles;
}

.orangeText {
  color: #ff3a2e;
  font-size: 150%;
  font-family: $corp-font1;
  font-weight: 500;
}

.descriptionText {
  font-size: 150%;
}

@include mobile {
  .logoImg {
    width: 40vw;
    padding-left: 20px;
  }

  .textTitle {
    font-size: 60px;
  }

  .orangeText {
    font-size: 18px;
    padding-top: 10px;
  }

  .descriptionText {
    line-height: 30px;
  }

  .item1 {
    width: 100vw;
  }

  .item2 {
    padding-right: 20px;
    padding-left: 20px;
  }

  .item3 {
    display: none;
  }
}
