@import "variables";

.imgComponentImg {
  width: 100%;
  height: auto;
  display: block;
}

@include mobile {
  .hide {
    display: none;
  }
}
